<template>
  <validation-observer v-slot="{ handleSubmit }" tag="div">
    <v-form @submit.prevent="handleSubmit(resetPassword)">
      <template #header>
        <v-form-row>
          <h1 class="password-recovery__title">Восстановление пароля</h1>
          <div class="password-recovery__desc">
            Для восстановления пароля укажите почту, которую вы использовали при входе.
          </div>
        </v-form-row>
      </template>
      <template #default>
        <v-form-row>
          <v-form-field label="ПОЧТА" :error-message="backendErrorMessage" rules="required|email">
            <template #default="{ validationErrors }">
              <v-input v-model="email" :is-error="!!validationErrors.length || !!backendErrorMessage" type="text" />
            </template>
          </v-form-field>
        </v-form-row>
      </template>
      <template #footer>
        <v-button primary type="submit">Выслать инструкцию</v-button>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VInput from '@/components/common/VInput.vue'
import { authService } from '@/services/http'
import { loadingService } from '@/services/loading'

export default {
  name: 'PasswordRecovery',
  components: { VForm, VFormRow, VFormField, VButton, VInput },
  data() {
    return {
      email: '',
      backendErrorMessage: '',
      loading: false
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  methods: {
    resetPassword() {
      this.loading = true
      authService
        .resetPassword(this.email)
        .then(() => {
          this.loading = false
          return this.$router.push({ name: 'notice-reset-password' })
        })
        .catch(error => {
          if (error.status === 400) {
            this.backendErrorMessage = 'Электронный адрес не найден'
          } else {
            this.errorBackendMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
